.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
    margin: 15px 15px 20px 15px;
    /* padding: 20px; */
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    line-height: 48px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}

.align-center {
  text-align: center;
}
.login-bg{
    background-color: black;
}

.login-main{
    display: table;
    width: 100%;
    height: 100vh;
    background: url(../Asserts/loginbg.jpg) center center;
    background-size: auto;
    background-repeat: no-repeat;
    opacity: 0.4;
}

.form-main {
    color: white;
    opacity: 1;
}

.login-container {
    background: rgba(0, 0, 0, 0.8);
    display: table-cell;
    margin: 0;
    padding: 0;
    text-align: center;
    vertical-align: middle;
}
